import React from 'react'
import '../../styles/elements/AlertTable.sass'
import { Alert, AlertEvent } from '../../types/api'

interface Props {
    data: Alert[]
    home: boolean
}

const AlertTable: React.FC<Props> = (props) => {
    const types = () => {
        const list = [
            'Criticità idraulica',
            'Criticità idrogeologica',
            'Criticità per temporali',
            'Vento',
            'Temperature estreme',
            'Neve',
            'Pioggia che gela',
            'Stato del mare',
            'Criticità costiera'
        ]
        if (props.home) list.splice(7, 8)

        return list
    }

    const joinEvents = () => {
        const e: AlertEvent[] = []

        props.data.map(alert => {
            return alert.events.map(event => {
                return e.push(event)
            })
        })

        if (props.home) e.splice(7, 8)

        return e
    }

    const events = joinEvents()
    const icons = ['idraulic', 'idrogeo', 'bad-weather', 'wind', 'extreme-temp', 'snow', 'ice-rain']

    return (
        <div className="chips-container">
            {types().map((type, index) => (
                <div key={`alert-home-${index}`} className={`chip alert-${events[index].color} tooltip-trigger`}>
                    <div className="alert-icon-wrapper">
                        <div className={`${icons[index]}-alert`} key={type}></div>
                    </div>
                    <span className="tooltip normal-tooltip dark-tooltip right-tooltip">{type}</span>
                </div>
            ))}
        </div>
    )
}
export default AlertTable
