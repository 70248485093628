import React, { useState } from 'react'
import '../../styles/InterventionsPage.sass'
import { useSelector } from 'react-redux'
import { Intervention } from '../../types/api'
import { ReducerData, Reducers, ReducerSettings, ReducerUser } from '../../types/reducers'
import InterventionTable from '../elements/InterventionTable'
import { Map, TileLayer, Marker } from 'react-leaflet'
import { activeInterventionIcon, interventionIcon, organizationIcon } from '../elements/MapIcons'

interface Viewport {
    center: number[],
    zoom: number
}

const InterventionsPage: React.FC = () => {
    const [selectedIntervention, setSelectedIntervention] = useState<Intervention>(null)
    const [viewport, setViewport] = useState<Viewport>({ center: [44.6060402, 11.6589668], zoom: 12 })

    const userReducer: ReducerUser = useSelector<Reducers, ReducerUser>(store => store.user)
    const settings: ReducerSettings = useSelector<Reducers, ReducerSettings>(store => store.settings)
    const data: ReducerData = useSelector<Reducers, ReducerData>(store => store.data)

    const clickIntervention = (intervention: Intervention) => {
        if (!selectedIntervention) {
            setSelectedIntervention(intervention)
        } else {
            if (intervention.uuid === selectedIntervention.uuid) {
                setSelectedIntervention(null)
            } else {
                setSelectedIntervention(intervention)
            }
        }
    }

    const interventions = data.interventions

    const user = userReducer

    if (!interventions) return null

    return (
        <div className={`App ${settings.drawerCollapsed ? 'resized-content' : ''}`}>
            <div className="body-section">
                <div className="section">
                    <div className="interventions-map-container">
                        <Map
                            className='map'
                            viewport={viewport}
                            onViewportChanged={(view) => setViewport(view)}
                        >
                            <TileLayer
                                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <TileLayer
                                url='https://maps.activepager.com/geoserver/gwc/service/tms/1.0.0/hydrologis:hydrants@EPSG%3A900913@pbf/{z}/{x}/{y}.pbf'
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker
                                position={[user.organization.position.latitude, user.organization.position.longitude]}
                                icon={organizationIcon}
                            />
                            {selectedIntervention ? (
                                <Marker
                                    position={[selectedIntervention.position.latitude, selectedIntervention.position.longitude]}
                                    icon={interventionIcon}
                                />
                            ) : null}
                            {
                                data.activeInterventions.map(activeIntervention => {
                                    return (
                                        <Marker
                                            position={[activeIntervention.position.latitude, activeIntervention.position.longitude]}
                                            icon={activeInterventionIcon}
                                        />
                                    )
                                })
                            }
                        </Map>
                    </div>
                </div>
                <div className="section history-section">
                    <h1 className="section-title">Storico interventi</h1>
                    <InterventionTable onClickIntervention={intervention => clickIntervention(intervention)} data={interventions}/>
                </div>
            </div>
        </div>
    )
}
export default InterventionsPage
