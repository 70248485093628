import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { closeDrawer, openDrawer } from '../../actions/settings'
import { logout } from '../../actions/user'
import '../../styles/elements/Drawer.sass'
import { Reducers, ReducerSettings, ReducerUser } from '../../types/reducers'
import Clock from '../elements/Clock'

const Drawer: React.FC = () => {
    const user: ReducerUser = useSelector<Reducers, ReducerUser>(store => store.user)
    const settings: ReducerSettings = useSelector<Reducers, ReducerSettings>(store => store.settings)
    const dispatch = useDispatch()

    const drawerInteract = (): void => {
        if (drawerCollapsed) dispatch(openDrawer())
        else dispatch(closeDrawer())
    }

    const { drawerCollapsed } = settings
    return (
        <div>
            <div className={`horizontal-bar ${drawerCollapsed ? 'horizontal-bar-resized' : ''}`}>
                <Clock/>
                <div className="right-buttons-section">
                    <div className="user-name">{`${user.firstName} ${user.lastName}`}</div>
                    <div className="user-image">
                        <div className="user-sprite"></div>
                    </div>
                    <span className="logout-btn" onClick={() => dispatch(logout())}>
                            <div className="logout-ico-btn tooltip-trigger">
                                <div className="logout-icon"/>
                                <span className="tooltip small-tooltip bottom-tooltip accent-tooltip">Esci</span>
                            </div>
                        </span>
                </div>
            </div>
            <div className={`navbar ${drawerCollapsed ? 'closed-navbar' : ''}`}>
                <div className="app-logo">
                    <div className="name-logo"></div>
                    <div className="tablet-logo"/>
                </div>
                <ul>
                    <li>
                        <NavLink to="/" exact activeClassName="active">
                            <div className="home-icon nav-ico"/>
                            <span>Overview</span>
                        </NavLink>
                    </li>
                    {/*<li>
                            <NavLink to="/weather" activeClassName="active">
                                <div className="cloud-icon nav-ico" />
                                <span>Meteo</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/rivers" activeClassName="active">
                                <div className="river-icon nav-ico" />
                                <span>Idrometri</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/alerts" activeClassName="active">
                                <div className="alert-icon nav-ico" />
                                <span>Allerte</span>
                            </NavLink>
                        </li>*/}
                    <li>
                        <NavLink to="/interventions" activeClassName="active">
                            <div className="interventions-icon nav-ico"/>
                            <span>Interventi</span>
                        </NavLink>
                    </li>
                </ul>
                <div className="settings-section">
                    <ul>
                        <li>
                            <NavLink to="/team" activeClassName="active">
                                <div className="team-icon nav-ico"/>
                                <span>Squadra</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/settings" activeClassName="active">
                                <div className="settings-icon nav-ico"></div>
                                <span>Impostazioni</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="bottom-section">
                    <div className="credits">
                        Made by <a href="https://massbit.dev/">Massbit</a>
                    </div>
                    <div className="bottom-button" onClick={() => drawerInteract()}>
                        <div className="arrow-icon"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Drawer
