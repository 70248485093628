import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getTeam } from '../../actions/organization'
import { REGEX } from '../../constants/constants'
import { REQUEST_STATUS } from '../../constants/enums'
import axios from '../../customComponents/customAxios'
import '../../styles/elements/ModalUser.sass'
import { Reducers, ReducerSettingsData, ReducerUser } from '../../types/reducers'
import SimpleLoader from '../elements/SimpleLoader'

interface Props {
    visible: boolean
    exit: () => void
    edit: boolean
}

const ModalUser: React.FC<Props> = (props) => {

    // State
    const [requestStatus, setRequestStatus] = useState<REQUEST_STATUS>(null)
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [username, setUsername] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [secondPassword, setSecondPassword] = useState<string>('')
    const [permission, setPermission] = useState<string>('')
    const [userImage, setUserImage] = useState<string>('')
    const [validEmailField, setValidEmailField] = useState<boolean>(true)
    const [validPasswordField, setValidPasswordField] = useState<boolean>(true)
    const [validConfirmPasswordField, setValidConfirmPasswordField] = useState<boolean>(true)

    // Redux
    const user: ReducerUser = useSelector<Reducers, ReducerUser>(store => store.user)
    const settingsData: ReducerSettingsData = useSelector<Reducers, ReducerSettingsData>(store => store.settingsData)
    const dispatch = useDispatch()

    const closeModal = () => {
        props.exit()
        // Reset state
        setRequestStatus(null)
        setFirstName('')
        setLastName('')
        setUsername('')
        setEmail('')
        setPassword('')
        setSecondPassword('')
        setPermission('')
        setValidEmailField(true)
        setValidPasswordField(true)
        setValidConfirmPasswordField(true)
    }

    const checkEmailField = (e) => {
        if (e) {
            setValidEmailField(REGEX.MAIL.test(e.target.value))
            setEmail(e.target.value)
        } else setValidEmailField(true)
    }

    const checkPasswordField = (e) => {
        if (e) {
            setValidPasswordField(REGEX.PASSWORD.test(e.target.value))
            setPassword(e.target.value)
        } else setValidPasswordField(true)
    }

    const checkConfirmPasswordField = (e) => {
        if (e) {
            setValidConfirmPasswordField(password === e.target.value)
            setSecondPassword(e.target.value)
        } else setValidConfirmPasswordField(true)
    }


    const createUser = async () => {
        setRequestStatus(REQUEST_STATUS.PENDING)

        try {
            await axios
                .post('dashboard/user/', {
                    first_name: firstName,
                    last_name: lastName,
                    username: username,
                    email: email,
                    password: password,
                    passed_organization: user.organization.id,
                    passed_permission: parseInt(permission, 10),
                    forecast_alerts_telegram_notification: false,
                    interventions_telegram_notification: false,
                    river_alerts_telegram_notification: false
                })
                .then(response => response.data)
            setRequestStatus(REQUEST_STATUS.OK)
            dispatch(getTeam())
        } catch (e) {
            setRequestStatus(REQUEST_STATUS.ERROR)
        }
    }

    const checkFields = () => {
        const fields = [firstName, lastName, username, email, password, secondPassword, permission]

        // Controlla se c'è un campo vuoto
        if (fields.some(field => field === ''))
            return false

        if (!validEmailField || !validPasswordField || !validConfirmPasswordField) return false

        // Se è in modifica controlla se le password sono diverse, se no il contrario
        if (props.edit)
            return password !== secondPassword
        else
            return password === secondPassword
    }

    const renderLoadingPage = () => {
        return (
            <div className="card modal">
                <div className="card-header">
                    <h1 className="card-title">Caricamento in corso...</h1>
                </div>
                <div className='card-body'>
                    <div className="card-loader-container">
                        <SimpleLoader/>
                    </div>
                </div>
            </div>
        )
    }

    const renderConfirmedPage = () => {
        return (
            <div className="card modal">
                <div className="card-header">
                    {
                        requestStatus === REQUEST_STATUS.OK ?
                            <h1 className="card-title">Ottimo</h1>
                            : <h1 className="card-title">Peccato</h1>
                    }
                </div>
                <div className='card-body'>
                    <span>
                        {
                            requestStatus === REQUEST_STATUS.OK ?
                                `L'utente ${firstName} ${lastName} è stato caricato con successo`
                                : 'Non è stato possibile creare l\'utente, riprova più tardi'
                        }
                    </span>
                </div>
                <div className='card-footer'>
                    <button
                        className='primary-button'
                        onClick={() => closeModal()}
                    >
                        Chiudi
                    </button>
                </div>
            </div>
        )
    }

    const renderCard = () => {
        if (requestStatus === REQUEST_STATUS.PENDING)
            return renderLoadingPage()

        if (requestStatus === REQUEST_STATUS.OK || requestStatus === REQUEST_STATUS.ERROR)
            return renderConfirmedPage()

        return (
            <div className="card modal">
                <div className="card-header">
                    {
                        props.edit ?
                            <h1 className="card-title">Modifica i dati del tuo profilo</h1>
                            : <h1 className="card-title">Aggiungi un utente alla tua squadra</h1>
                    }
                    <div className="remove-icon" onClick={() => closeModal()}/>
                </div>
                <div className='card-body'>
                    <div className="fields-container">
                        <div className='input-row'>
                            <div className='input-container'>
                                <div className='group'>
                                    <input
                                        name='mail'
                                        value={email}
                                        onChange={e => checkEmailField(e)}
                                        type='text'
                                        required
                                    />
                                    <span className='highlight'/>
                                    <span className={`bar ${validEmailField ? '' : 'error-bar'}`}/>
                                    <label className={validEmailField ? '' : 'error-label'}>Mail</label>
                                </div>
                            </div>
                        </div>
                        <div className='input-row'>
                            <div className='input-container'>
                                <div className='group'>
                                    <input
                                        name='username'
                                        value={username}
                                        onChange={e => setUsername(e.target.value)}
                                        type='text'
                                        required
                                    />
                                    <span className='highlight'/>
                                    <span className='bar'/>
                                    <label>Username</label>
                                </div>
                            </div>
                        </div>
                        <div className='input-row'>
                            {
                                props.edit ?
                                    <label>Cambio password</label> :
                                    <label>Creazione password</label>

                            }
                        </div>
                        <div className='input-row'>
                            <div className='input-container'>
                                <div className='group'>
                                    <input
                                        name='oldPassword'
                                        value={password}
                                        onChange={e => checkPasswordField(e)}
                                        type='password'
                                        required
                                    />
                                    <span className='highlight'/>
                                    <span className={`bar ${validPasswordField ? '' : 'error-bar'}`}/>
                                    {
                                        props.edit ?
                                            <label className={validPasswordField ? '' : 'error-label'}>Vecchia password</label>
                                            : <label className={validPasswordField ? '' : 'error-label'}>Password</label>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='input-row'>
                            <div className='input-container'>
                                <div className='group'>
                                    <input
                                        name='newPassword'
                                        value={secondPassword}
                                        onChange={e => checkConfirmPasswordField(e)}
                                        type='password'
                                        required
                                    />
                                    <span className='highlight'/>
                                    <span className={`bar ${validConfirmPasswordField ? '' : 'error-bar'}`}/>
                                    {
                                        props.edit ?
                                            <label className={validConfirmPasswordField ? '' : 'error-label'}>Nuova password</label>
                                            : <label className={validConfirmPasswordField ? '' : 'error-label'}>Conferma password</label>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="image-edit-panel">
                        {
                            props.edit ?
                                <div className="photo-wrapper">
                                    {userImage !== '' ?
                                        <img alt={username} src={userImage}/> :
                                        <div className="big-user-sprite"></div>
                                    }
                                    <div className="photo-action">
                                        <label htmlFor="choose-user-image">Modifica la foto del profilo</label>
                                        <input id="choose-user-image" onChange={(e) => setUserImage(e.target.files[0].name)} type="file"
                                               accept=".png, .jpg, .jpeg" data-max-size='5 * (1024 * 1024)'/>
                                    </div>
                                </div> :
                                <div className="identity-wrapper">
                                    <div className='input-row'>
                                        <div className='input-container'>
                                            <div className='group'>
                                                <input
                                                    name='firstName'
                                                    value={firstName}
                                                    onChange={e => setFirstName(e.target.value)}
                                                    type='text'
                                                    required
                                                />
                                                <span className='highlight'/>
                                                <span className='bar'/>
                                                <label>Nome</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='input-row'>
                                        <div className='input-container'>
                                            <div className='group'>
                                                <input
                                                    name='lastName'
                                                    value={lastName}
                                                    onChange={e => setLastName(e.target.value)}
                                                    type='text'
                                                    required
                                                />
                                                <span className='highlight'/>
                                                <span className='bar'/>
                                                <label>Cognome</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                        {
                            !props.edit ?
                                <select
                                    value={permission}
                                    onChange={e => setPermission(e.target.value)}
                                >
                                    <option value="" disabled={true}>Scegli un permesso</option>
                                    {
                                        settingsData.permissions.map(element => (
                                            <option
                                                key={element.id.toString()}
                                                value={element.id}
                                            >
                                                {
                                                    element.name
                                                }
                                            </option>
                                        ))
                                    }
                                </select> : ''
                        }
                    </div>
                </div>
                <div className='card-footer'>
                    {
                        props.edit ? (
                            <button
                                className='primary-button'
                                disabled={!checkFields()}
                            >
                                Conferma modifiche
                            </button>
                        ) : (
                            <button
                                className='primary-button'
                                disabled={!checkFields()}
                                onClick={() => createUser()}
                            >
                                Crea nuovo utente
                            </button>
                        )
                    }
                    <button className='important-button' onClick={() => closeModal()}>Annulla</button>
                </div>
            </div>
        )
    }

    return (
        <div className={`modal-panel ${props.visible ? 'visible' : ''}`}>
            <div className="dark-cover"/>
            {
                renderCard()
            }
        </div>
    )
}

export default ModalUser
