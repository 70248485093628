import React, { useState } from 'react'
import '../../styles/elements/Login.sass'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../actions/user'
import { ReducerGraphic, Reducers } from '../../types/reducers'

const Login: React.FC = () => {

    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false)

    const graphic: ReducerGraphic = useSelector<Reducers, ReducerGraphic>(store => store.graphic)
    const dispatch = useDispatch()

    const checkEmptyData = () => {
        return username === '' || password === ''
    }

    return (
        <div className="login-panel">
            <div className="name-logo"></div>
            <div className="card login">
                <div className="login-wrapper">
                    <div className="input-section">
                        <div className="input-container">
                            <div className="group">
                                <input
                                    name="username"
                                    onChange={e => setUsername(e.target.value)}
                                    type="text"
                                    required
                                />
                                <span className="highlight"/>
                                <span className="bar"/>
                                <label>Username</label>
                            </div>
                        </div>
                        <div className="input-container">
                            <div className="group">
                                <input
                                    name="password"
                                    onChange={e => setPassword(e.target.value)}
                                    type={passwordVisible ? 'text' : 'password'}
                                    required
                                />
                                <span className="highlight"/>
                                <span className="bar"/>
                                <label>Password</label>
                            </div>
                            <div
                                className="see-button"
                                onClick={() => setPasswordVisible(!passwordVisible)}
                            >
                                <i className="material-icons">{passwordVisible ? 'visibility_off' : 'visibility'}</i>
                            </div>
                        </div>
                    </div>
                </div>
                {graphic.loginError ? <span className="error">Username o password errati</span> : null}
                <button
                    onClick={() => {
                        dispatch(login({
                            username: username,
                            password: password
                        }))
                    }}
                    disabled={checkEmptyData()}
                >
                    Login
                </button>
            </div>
            <div className="credits">
                Made by <a href="https://massbit.dev/">Massbit</a>
            </div>
        </div>
    )
}
export default Login
