import originalAxios from 'axios'
import { API_MAIN_ROOT } from '../constants/constants'
import { toCamelCase } from '../functions'

const axios = originalAxios.create({
    baseURL: API_MAIN_ROOT
})

axios.interceptors.response.use(
    response => {
        response.data = toCamelCase(response.data)
        // Do something with response data
        return response
    },
    error => {
        // Do something with response error
        return Promise.reject(error)
    }
)

export default axios
