import produce from 'immer'
import { ReducerOrganization } from '../types/reducers'

const defaultValue: ReducerOrganization = {
    team: []
}

export default function dataState(state = defaultValue, action: any) {
    return produce(state, (draft) => {
        switch (action.type) {
            case 'organization/getTeam_FULFILLED':
                draft.team = action.payload
                break
            default:
                break
        }
    })
}
