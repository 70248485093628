export enum ALERT_GRAVITY {
    WHITE,
    GREEN,
    YELLOW,
    ORANGE,
    RED
}

export enum THRESHOLDS {
    ZERO = 'threshold-zero',
    ONE = 'threshold-one',
    TWO = 'threshold-two',
    THREE = 'threshold-threee'
}

export enum REQUEST_STATUS {
    ERROR = 'ERROR',
    PENDING = 'PENDING',
    OK = 'DONE'
}
