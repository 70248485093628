import produce from 'immer'
import { SYNC_PERMISSIONS } from '../actions/settings_data'
import { ReducerSettingsData } from '../types/reducers'

const defaultValue: ReducerSettingsData = {
    permissions: []
}

export default function dataState(state = defaultValue, action: any) {
    return produce(state, draft => {
        switch (action.type) {
            case `${SYNC_PERMISSIONS}_FULFILLED`:
                return{
                    ...state,
                    permissions: action.payload
                }
            default:
                break
        }
    })
}
