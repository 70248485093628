import React, { useState } from 'react'
import customMoment from '../../customComponents/customMoment'
import { convertMonthFromDate } from '../../functions'

const Clock: React.FC = () => {
    const [time, setTime] = useState<string>(customMoment().format('HH:mm:ss'))
    const [date, setDate] = useState<string>(convertMonthFromDate(customMoment().format('DD M YYYY'), ' '))

    const intervalID = setInterval(() => tick(), 1000)

    const componentWillUnmount = () => {
        clearInterval(intervalID)
    }

    const tick = () => {
        setTime(customMoment().format('HH:mm:ss'))
        setDate(convertMonthFromDate(customMoment().format('DD M YYYY'), ' '))
    }

    return (
        <div className="time-section">
            <span className="date-value">{date}</span>
            <div className="time-container">
                <span className="time-value">{time}</span>
            </div>
        </div>
    )
}
export default Clock
