import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { User, Viewport } from '../../types/interfaces'
import { ReducerOrganization, Reducers, ReducerSettings, ReducerUser } from '../../types/reducers'
import '../../styles/TeamPage.sass'
import { organizationIcon } from '../elements/MapIcons'
import ModalDeleteUser from '../elements/ModalDeleteUser'
import ModalUser from '../elements/ModalUser'
import { Map, TileLayer, Marker } from 'react-leaflet'

const TeamPage: React.FC = () => {
    const [teamModalVisible, setTeamModalVisible] = useState<boolean>(false)
    const [deleteModalUser, setDeleteModalUser] = useState<User>(null)

    const settings: ReducerSettings = useSelector<Reducers, ReducerSettings>(store => store.settings)
    const organization: ReducerOrganization = useSelector<Reducers, ReducerOrganization>(store => store.organization)
    const user: ReducerUser = useSelector<Reducers, ReducerUser>(store => store.user)

    const [viewport, setViewport] = useState<Viewport>({ center: [user.organization.position.latitude, user.organization.position.longitude], zoom: 12 })

    const renderUser = (organizationUser: User) => {
        return (
            <div key={organizationUser.id.toString()} className="team-member">
                <div className="member-image">
                    {
                        user.permission.manageUsers ? (
                            <div className="remove-button" onClick={() => setDeleteModalUser(organizationUser)}>
                                <div className="remove-icon"/>
                            </div>
                        ) : null
                    }
                    <div className="member-photo"/>
                </div>
                <span className="member-name">{`${organizationUser.firstName} ${organizationUser.lastName}`}</span>
            </div>
        )
    }

    return (
        <div className={`App ${settings.drawerCollapsed ? 'resized-content' : ''}`}>
            <div className="card">
                <div className="card-header">
                    <h1 className="card-title">
                        La tua organizzazione
                    </h1>
                </div>
                <div className="card-body">
                    <span>{user.organization.name}</span>

                    <span>
                        {
                            `${user.organization.address} ${user.organization.streetNumber}, ` +
                                `${user.organization.city} (${user.organization.state}) ` +
                                `${user.organization.postalCode} ${user.organization.country}`
                        }
                    </span>
                    <Map
                        className='small-map-container'
                        viewport={viewport}
                        onViewportChanged={(view) => setViewport(view)}
                    >
                        <TileLayer
                            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker
                            position={[user.organization.position.latitude, user.organization.position.longitude]}
                            icon={organizationIcon}
                        />
                    </Map>
                </div>
            </div>
            <div className="card team-card">
                <div className="card-header">
                    <h1 className="card-title">
                        {
                            `La tua squadra (${organization.team.length} utenti)`
                        }
                    </h1>
                </div>
                <div className="card-body">
                    {
                        organization.team.length === 0 ? (
                            <span>Al momento la tua squadra non è composta da nessun utente, per cominciare aggiungine uno.</span>
                        ) : (
                            <div className="team-container">
                                {
                                    organization.team.map(user => renderUser(user))
                                }
                                {
                                    user.permission.manageUsers ? (
                                        <div className="team-member add-member">
                                            <div className="add-image" onClick={() => setTeamModalVisible(true)}>
                                                <div className="add-icon"/>
                                            </div>
                                            <span className="member-name">Aggiungi un nuovo membro</span>
                                        </div>
                                    ) : null
                                }
                            </div>
                        )
                    }
                </div>
            </div>
            <ModalUser visible={teamModalVisible} exit={() => setTeamModalVisible(false)} edit={false}/>
            <ModalDeleteUser user={deleteModalUser} exit={() => setDeleteModalUser(null)}/>
        </div>
    )
}
export default TeamPage
