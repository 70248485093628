import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Reducers, ReducerSettings, ReducerUser } from '../../types/reducers'
import '../../styles/SettingsPage.sass'
import QRCode from 'qrcode.react'
import ModalUser from '../elements/ModalUser'
import NotificationCheckbox from '../elements/NotificationCheckbox'

const SettingsPage: React.FC = () => {

    const [userModalVisible, setUserModalVisible] = useState<boolean>(false)

    const userReducer: ReducerUser = useSelector<Reducers, ReducerUser>(store => store.user)
    const settings: ReducerSettings = useSelector<Reducers, ReducerSettings>(store => store.settings)

    const user = userReducer
    return (
        <div className={`App ${settings.drawerCollapsed ? 'resized-content' : ''}`}>
            <ModalUser visible={userModalVisible} exit={() => setUserModalVisible(false)} edit={true}/>
            <div className="card settings-card">
                <div className="card-header">
                    <h1 className="card-title">Riepilogo dati</h1>
                </div>
                <div className="card-body">
                    <div className="photo-wrapper">
                        <div className="big-user-sprite"></div>
                    </div>
                    <div className="info-wrapper">
                        <div className="info-list">
                            <span>Nome utente: {`${user.firstName} ${user.lastName}`}</span>
                            <span>Associazione: {`${user.organization.name}`}</span>
                            <span>Permessi utente: {`${user.permission.name}`}</span>
                        </div>
                        <div className="actions-list">
                            <button className="primary-button" onClick={() => setUserModalVisible(true)}>Modifica i dati</button>
                            <button className="important-button">Elimina il profilo</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section telegram-section">
                <h1 className="section-title">Collegamento a Telegram</h1>
                <div className="card settings-card telegram-card">
                    <div className="telegram-sprite"></div>
                    {
                        !user.telegramUser ? (
                            <div className="actions-list">
                                <p className="telegram-hint">Collegati al bot di Telegram per rimanere sempre aggiornato tramite notifica.</p>
                                <div className="qr-container">
                                    <QRCode value={`https://t.me/mida_alert_bot?start=${user.uuid}`}/>
                                </div>
                                <a href={`https://t.me/mida_alert_bot?start=${user.uuid}`}>@mida_alert_bot</a>
                            </div>
                        ) : (
                            <div className="actions-list">
                                <button className="important-button">Elimina associazione bot</button>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="card settings-card">
                <div className="card-header">
                    <h1 className="card-title">Notifiche</h1>
                </div>
                <div className="card-body">
                    <table className="notify-table">
                        <thead>
                        <tr>
                            <th></th>
                            <th>Telegram</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="name-element">Allerte fiumi</td>
                            <td className="check-element">
                                <NotificationCheckbox
                                    itemKey="river_alerts_telegram_notification"
                                    value={user.telegramNotifications.riverAlerts}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="name-element">Allerte meteo</td>
                            <td className="check-element">
                                <NotificationCheckbox
                                    itemKey="forecast_alerts_telegram_notification"
                                    value={user.telegramNotifications.forecastAlerts}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="name-element">Interventi</td>
                            <td className="check-element">
                                <NotificationCheckbox
                                    itemKey="interventions_telegram_notification"
                                    value={user.telegramNotifications.interventions}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
export default SettingsPage
