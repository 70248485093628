import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { syncUser } from '../../actions/user'
import axios from '../../customComponents/customAxios'
import { Reducers, ReducerUser } from '../../types/reducers'
import SimpleLoader from './SimpleLoader'

interface Props {
    itemKey: string
    value: boolean
}

const CheckBox: React.FC<Props> = (props) => {
    const [requestLoading, setRequestLoading] = useState<boolean>(false)
    const user: ReducerUser = useSelector<Reducers, ReducerUser>(store => store.user)
    const dispatch = useDispatch()

    async function changeNotificationStatus() {
        try {
            setRequestLoading(true)
            // Faccio la richiesta alle api per modificare il valore
            await axios
                .patch(`dashboard/user/${user.id}/`, {
                    [props.itemKey]: !props.value
                })
                .then(response => response.data)
            // Sincronizzo i dati dell'utente
            dispatch(syncUser())
            setRequestLoading(false)
        } catch (e) {
            setRequestLoading(false)
        }
    }

    if (requestLoading)
        return <SimpleLoader/>

    return (
        <div className="md-checkbox">
            <input
                type="checkbox"
                id={props.itemKey}
                checked={props.value}
                // Ho usato onChange perchè è più corretto di onClick per i valori non read only
                onChange={() => changeNotificationStatus()}
            />
            <label htmlFor={props.itemKey}/>
        </div>
    )
}

export default CheckBox
