// Se è in sviluppo si può decidere se scegliere tra le api online o quelle locali, se è in produzione invece è sempre online
import { DEBUG, URL_API_OFFLINE, URL_API_ONLINE } from './env_variables'

export const API_MAIN_ROOT = DEBUG ? URL_API_OFFLINE : URL_API_ONLINE

export const ALERT_COLORS = {
    WHITE: '#fff',
    GREEN: '#39FF14',
    YELLOW: '#FFEA00',
    ORANGE: '#FF8F00',
    RED: '#FF3D00'
}

export const WORK_SHIFTS = ['A', 'B', 'C', 'D']

export const REGEX = {
    PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
    MAIL: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
}
