import customMoment from './customComponents/customMoment'
import { ALERT_COLORS, WORK_SHIFTS } from './constants/constants'
import { ALERT_GRAVITY, THRESHOLDS } from './constants/enums'
import { Alert, River, SensorData, Threshold, Workshift } from './types/api'
import { Month, Zones } from './types/interfaces'

/**
 * Cambia le key formattate in snake_case o in kebab-case in camelCase
 * @param obj
 */
export function toCamelCase(obj: any): any {
    let rtn = obj
    if (typeof obj === 'object' && obj !== null) {
        if (obj instanceof Array) {
            rtn = obj.map(toCamelCase)
        } else {
            rtn = {}
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    const newKey = key.replace(/(_\w)|(-\w)/g, k => k[1].toUpperCase())
                    rtn[newKey] = toCamelCase(obj[key])
                }
            }
        }
    }
    return rtn
}

/**
 * Deep copy
 * @param data
 */
export function deepCopy(data: any) {
    return JSON.parse(JSON.stringify(data))
}

export function transformSensorTemperature(temperatures: SensorData[]) {
    return temperatures
        .map(temperature => {
            return {
                ...temperature,
                v: temperature.v - 273.15
            }
        })
        .reverse()
}

export function transformRiverLevel(river: River) {
    return {
        ...river,
        data: river.data.reverse()
    }
}

/**
 * Prende la soglia di allerta del fiume
 * @param level
 * @param thresholds
 */
export function getLevelClass(level: number, thresholds: Threshold[]): THRESHOLDS {
    // Se è meno della soglia uno ritorna la classe 0
    if (level < thresholds[0].level) {
        return THRESHOLDS.ZERO
    }

    const thresholdsCopy = deepCopy(thresholds)

    return thresholdsCopy
        .sort((a, b) => b.level - a.level) // Faccio il sort dell'array dal più grande al più piccolo
        .find(t => level >= t.level).class // Cerca la prima soglia più grande del livello e ritorna la classe
}

export function convertAlert(color: string) {
    switch (color) {
        case 'white':
            return {
                gravity: ALERT_GRAVITY.WHITE,
                colorCode: ALERT_COLORS.WHITE
            }
        case 'green':
            return {
                gravity: ALERT_GRAVITY.GREEN,
                colorCode: ALERT_COLORS.GREEN
            }
        case 'yellow':
            return {
                gravity: ALERT_GRAVITY.YELLOW,
                colorCode: ALERT_COLORS.YELLOW
            }
        case 'orange':
            return {
                gravity: ALERT_GRAVITY.ORANGE,
                colorCode: ALERT_COLORS.ORANGE
            }
        case 'red':
            return {
                gravity: ALERT_GRAVITY.RED,
                colorCode: ALERT_COLORS.RED
            }
    }
}

/**
 * Trasforma la stringa delle allerte in un array
 * @param alert
 * @return Alert[]
 */
export function transformAlertData(alert): Alert[] {
    return alert.map(alert => {
        return {
            ...alert,
            events: alert.eventi
                .split(',')
                .map(event => {
                    const values = event.split(':')
                    const convertedAlert = convertAlert(values[1])
                    return {
                        type: values[0],
                        color: values[1],
                        gravity: convertedAlert.gravity,
                        colorCode: convertedAlert.colorCode
                    }
                })
                .reverse()
        }
    })
}

export function transformAlertZones(zones: Alert[]): Zones {
    return {
        a: zones.find(zone => zone.area === 'A'),
        b: zones.find(zone => zone.area === 'B'),
        c: zones.find(zone => zone.area === 'C'),
        d: zones.find(zone => zone.area === 'D'),
        e: zones.find(zone => zone.area === 'E'),
        f: zones.find(zone => zone.area === 'F'),
        g: zones.find(zone => zone.area === 'G'),
        h: zones.find(zone => zone.area === 'H')
    }
}

export function convertMonthFromNumber(month: number): Month {
    switch (month) {
        case 1:
            return {
                long: 'Gennaio',
                short: 'Gen'
            }
        case 2:
            return {
                long: 'Febbraio',
                short: 'Feb'
            }
        case 3:
            return {
                long: 'Marzo',
                short: 'Mar'
            }
        case 4:
            return {
                long: 'Aprile',
                short: 'Apr'
            }
        case 5:
            return {
                long: 'Maggio',
                short: 'Mag'
            }
        case 6:
            return {
                long: 'Giugno',
                short: 'Giu'
            }
        case 7:
            return {
                long: 'Luglio',
                short: 'Lug'
            }
        case 8:
            return {
                long: 'Agosto',
                short: 'Ago'
            }
        case 9:
            return {
                long: 'Settembre',
                short: 'Set'
            }
        case 10:
            return {
                long: 'Ottobre',
                short: 'Ott'
            }
        case 11:
            return {
                long: 'Novembre',
                short: 'Nov'
            }
        case 12:
            return {
                long: 'Dicembre',
                short: 'Dic'
            }
        default:
            return null
    }
}

export function convertMonthFromDate(date: string, split: string): string {
    const splittedDate = date.split(split)
    return `${splittedDate[0]} ${convertMonthFromNumber(parseInt(splittedDate[1], 10)).long} ${splittedDate[2]}`
}

/**
 * Calcolo il turno di oggi
 * @return string
 */
export function getActualWorkShift(): Workshift {
    // Inizializzo i valori delle date
    const startDate = customMoment('01/01/2019', 'DD/MM/YYYY')
    const nowDate = customMoment()

    // Controllo che siano già passate le 7
    const shiftChanged = customMoment('8:00', 'HH:mm') < nowDate

    // Controllo che sia già nel turno notturno
    const nightShift = customMoment('20:00', 'HH:mm') < nowDate

    // Prendo la differenza in giorni tra le due date
    const dayDiff = nowDate.diff(startDate, 'day')

    // Calcolo il turno di oggi
    const shiftIndex = (dayDiff % 4) - (shiftChanged ? 0 : 1) - (nightShift ? 1 : 0)

    return {
        shift: WORK_SHIFTS[shiftIndex],
        night: nightShift
    }
}

/**
 * Converte la distanza in km se necessario
 * @param distance
 * @returns {string}
 */
export function convertDistance(distance) {
    return parseInt(distance, 10) >= 1000 ? `${(parseFloat(distance) / 1000).toFixed(1)} km` : `${parseInt(distance, 10)} m`
}

/**
 * Converte l'orario in base all'ora legale o no
 * E' usato per la api di ingv
 * @param time
 */
export function convertTimeToDST(time: any) {
    if (time.isDST()) return time.add(2, 'hours')

    return time.add(1, 'hours')
}
