import React from 'react'
import customMoment from '../../customComponents/customMoment'
import '../../styles/elements/InterventionTable.sass'
import { Intervention } from '../../types/api'

interface Props {
    data: Intervention[]
    onClickIntervention: (intervention: Intervention) => void
}

const InterventionTable: React.FC<Props> = (props) => {

    const transformDate = (timestamp: string) => {
        return {
            date: customMoment(timestamp).format('DD/MM/YYYY'),
            time: customMoment(timestamp).format('HH:mm')
        }
    }
    return (
        <div>
            <div className="interventions-filters card">
                <span className="interventions-filters__title">Filtri:</span>
                <select name="" id="" defaultValue="zero">
                    <option value="zero" disabled={true}>Scegli un tipo di intervento</option>
                    <option value="uno">uno</option>
                    <option value="due">due</option>
                </select>
                <select name="" id="" defaultValue="zero">
                    <option value="zero" disabled={true}>Scegli un comando provinciale</option>
                    <option value="uno">BO</option>
                    <option value="due">FE</option>
                </select>
                <button className="primary-button">Applica</button>
            </div>
            <table className="interventions-table">
                <thead>
                <tr>
                    <th></th>
                    <th>Intervento</th>
                    <th>Inviato da</th>
                    <th>Data</th>
                    <th>Ora</th>
                </tr>
                </thead>
                <tbody>
                {props.data.map((element, index) => {
                    return (
                        <tr key={index}>
                            <td className="action-icon-container">
                                <div className="action-icon" onClick={() => props.onClickIntervention(element)}>
                                    <div className="place-icon"/>
                                </div>
                            </td>
                            <td>{element.title}</td>
                            <td>{element.sender}</td>
                            <td>{transformDate(element.startTime).date}</td>
                            <td>{transformDate(element.startTime).time}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}
export default InterventionTable
