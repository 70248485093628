import L from 'leaflet'
import activeInterventionIconImage from '../../media/images/active_intervention_marker.png'
import interventionIconImage from '../../media/images/intervention_marker.png'
import organizationIconImage from '../../media/images/organization_marker.png'
import riverIconImage from '../../media/images/river_marker.png'

export const organizationIcon = new L.Icon({
    iconUrl: organizationIconImage,
    iconRetinaUrl: organizationIconImage,
    iconAnchor: [5, 55],
    iconSize: [50, 50],
})

export const riverIcon = new L.Icon({
    iconUrl: riverIconImage,
    iconRetinaUrl: riverIconImage,
    iconAnchor: [5, 55],
    iconSize: [50, 50],
})

export const interventionIcon = new L.Icon({
    iconUrl: interventionIconImage,
    iconRetinaUrl: interventionIconImage,
    iconAnchor: [5, 55],
    iconSize: [50, 50],
})

export const activeInterventionIcon = new L.Icon({
    iconUrl: activeInterventionIconImage,
    iconRetinaUrl: activeInterventionIconImage,
    iconAnchor: [5, 55],
    iconSize: [50, 50],
})
