interface CloseDrawerAction {
    type: 'settings/closeDrawer'
}

export function closeDrawer(): CloseDrawerAction {
    return {
        type: 'settings/closeDrawer'
    }
}

export interface OpenDrawerAction {
    type: 'settings/openDrawer'
}

export function openDrawer(): OpenDrawerAction {
    return {
        type: 'settings/openDrawer'
    }
}
