import React from 'react'
import '../../styles/elements/MeteoCard.sass'
import { ForecastData } from '../../types/api'

interface Props {
    forecast: ForecastData
}

const WeatherGrid: React.FC<Props> = (props) => {
        const { forecast } = props.forecast

        return (
            <div className="meteo-panel">
                <div className="meteo-card card">
                    <div className="left">
                        <div className={forecast.currently.icon} />
                    </div>
                    <div className="right">
                        <span className="temp">{forecast.currently.temperature}°</span>
                        <div className="min-max-container">
                            <span className="max">{forecast.daily.data[0].temperatureMax}°</span>
                            <span className="min">{forecast.daily.data[0].temperatureMin}°</span>
                        </div>
                    </div>
                </div>
                <div className="meteo-card card">
                    <div className="left">
                        <div className="wind" />
                    </div>
                    <div className="right">
                        <span className="gradient-text">Vento</span>
                        <span className="main-text" id="wind-value">
                            {forecast.currently.windSpeed}
                        </span>
                    </div>
                </div>
                <div className="meteo-card card">
                    <div className="left">
                        <div className="water-icon" />
                    </div>
                    <div className="right">
                        <span className="gradient-text">Umidità</span>
                        <span className="main-text" id="percentage-value">
                            {forecast.currently.humidity}
                        </span>
                    </div>
                </div>
                <div className="meteo-card card">
                    <div className="left">
                        <div className="clear-day" />
                    </div>
                    <div className="right">
                        <span className="gradient-text">Indice uv</span>
                        <span className="main-text">{forecast.currently.uvIndex}</span>
                    </div>
                </div>
                <div className="meteo-card card">
                    <div className="left">
                        <div className="rain" />
                    </div>
                    <div className="right">
                        <span className="gradient-text">Precipitazioni</span>
                        <span className="main-text" id="percentage-value">
                            {forecast.currently.precipProbability}
                        </span>
                    </div>
                </div>
            </div>
        )
}

export default WeatherGrid
