import produce from 'immer'
import { ReducerSettings } from '../types/reducers'

const defaultValue: ReducerSettings = {
    drawerCollapsed: false
}

export default function dataState(state = defaultValue, action: any) {
    return produce(state, draft => {
        switch (action.type) {
            case 'settings/openDrawer':
                draft.drawerCollapsed = false
                break
            case 'settings/closeDrawer':
                draft.drawerCollapsed = true
                break
            default:
                break
        }
    })
}
