import React from 'react'
import { Reducers, ReducerSettings } from '../../types/reducers'
import { useSelector } from 'react-redux'
import '../../styles/elements/AlertTable.sass'

const AlertsPage: React.FC = () => {
    const settings: ReducerSettings = useSelector<Reducers, ReducerSettings>(store => store.settings)

    const places: string[] = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2', 'D1', 'D2', 'E1', 'E2', 'F', 'G1', 'G2', 'H1', 'H2']
    const alerts: string[] = [
        'Criticità idraulica',
        'Criticità idrogeologica',
        'Criticità per temporali',
        'Vento',
        'Temperature estreme',
        'Neve',
        'Pioggia che gela',
        'Stato del mare',
        'Criticità costiera'
    ]
    return (
        <div className={`App ${settings.drawerCollapsed ? 'resized-content' : ''}`}>
            <table>
                <thead>
                <tr>
                    <th/>
                    {places.map(element => {
                        return <th>{element}</th>
                    })}
                </tr>
                </thead>
                <tbody>
                {alerts.map(element => {
                    return (
                        <tr>
                            <td>{element}</td>
                            {places.forEach((el, index) => {
                                return (
                                    <td>
                                        <div key={index} className="point alert-green"/>
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}
export default AlertsPage
