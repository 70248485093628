import { persistCombineReducers } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import data from './data'
import graphic from './graphic'
import organization from './organization'
import settings from './settings'
import user from './user'
import settingsData from './settings_data'

const config = {
    key: 'root',
    storage,
    blacklist: ['data', 'graphic', 'organization']
}

const rootReducer = persistCombineReducers(config, {
    data,
    user,
    graphic,
    settings,
    organization,
    settingsData
})

export default rootReducer
