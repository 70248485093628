import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { getTeam } from '../../actions/organization'
import { REQUEST_STATUS } from '../../constants/enums'
import axios from '../../customComponents/customAxios'
import '../../styles/elements/ModalUser.sass'
import { User } from '../../types/interfaces'
import SimpleLoader from '../elements/SimpleLoader'

interface Props {
    user: User
    exit: () => void
}

const ModalDeleteUser: React.FC<Props> = (props) => {

    // State
    const [requestStatus, setRequestStatus] = useState<REQUEST_STATUS>(null)
    const [username, setUsername] = useState<string>('')

    // Redux
    const dispatch = useDispatch()

    const closeModal = () => {
        props.exit()
        // Reset state
        setRequestStatus(null)
        setUsername('')
    }

    const deleteUser = async () => {
        setRequestStatus(REQUEST_STATUS.PENDING)

        // controllare se lo username inserito corrisponde all'utente che si vuole eliminare

        try {
            await axios
                .patch('', {})
                .then(response => response.data)
            setRequestStatus(REQUEST_STATUS.OK)
            dispatch(getTeam())
        } catch (e) {
            setRequestStatus(REQUEST_STATUS.ERROR)
        }
    }

    const checkUsername = () => {
        return username.length !== 0
    }

    const renderLoadingPage = () => {
        return (
            <div className="card modal">
                <div className="card-header">
                    <h1 className="card-title">Eliminazione in corso...</h1>
                </div>
                <div className='card-body'>
                    <div className="card-loader-container">
                        <SimpleLoader/>
                    </div>
                </div>
            </div>
        )
    }

    const renderConfirmedPage = () => {
        return (
            <div className="card modal">
                <div className="card-header">
                    {
                        requestStatus === REQUEST_STATUS.OK ?
                            <h1 className="card-title">Ottimo</h1>
                            : <h1 className="card-title">Peccato</h1>
                    }
                </div>
                <div className='card-body'>
                    <span>
                        {
                            requestStatus === REQUEST_STATUS.OK ?
                                `L'utente è stato eliminato con successo`
                                : 'Non è stato possibile eliminare l\'utente, riprova più tardi'
                        }
                    </span>
                </div>
                <div className='card-footer'>
                    <button
                        className='primary-button'
                        onClick={() => closeModal()}
                    >
                        Chiudi
                    </button>
                </div>
            </div>
        )
    }

    const renderCard = () => {
        if (requestStatus === REQUEST_STATUS.PENDING)
            return renderLoadingPage()

        if (requestStatus === REQUEST_STATUS.OK || requestStatus === REQUEST_STATUS.ERROR)
            return renderConfirmedPage()

        return (
            <div className="card modal">
                <div className="card-header">
                    <h1 className="card-title">Elimina l'utente selezionato</h1>
                    <div className="remove-icon" onClick={() => closeModal()}/>
                </div>
                <div className='card-body body-column'>
                    <p className="delete-hint">
                        Per eliminare l'utente <span className="code">{props.user.username}</span> compila il campo qui sotto con il suo username.
                    </p>
                    <div className="fields-container">
                        <div className='input-row'>
                            <div className='input-container'>
                                <div className='group'>
                                    <input
                                        name='username'
                                        value={username}
                                        onChange={e => setUsername(e.target.value)}
                                        type='text'
                                        required
                                    />
                                    <span className='highlight'/>
                                    <span className="bar" />
                                    <label>Username</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-footer'>
                    <button
                        className='primary-button'
                        disabled={!checkUsername()}
                        onClick={() => deleteUser()}
                    >
                        Elimina
                    </button>
                    <button className='important-button' onClick={() => closeModal()}>Annulla</button>
                </div>
            </div>
        )
    }

    if (props.user === null)
        return null

    return (
        <div className={`modal-panel visible`}>
            <div className="dark-cover"/>
            {
                renderCard()
            }
        </div>
    )

}

export default ModalDeleteUser
