import { LOGIN, LOGOUT, SYNC_USER } from '../actions/user'
import axios from '../customComponents/customAxios'

import produce from 'immer'
import { ReducerUser } from '../types/reducers'

const defaultValue: ReducerUser = {
    token: null,
    username: '',
    id: null,
    uuid: null,
    firstName: '',
    lastName: '',
    mail: '',
    telegramNotifications: null,
    telegramUser: null,
    organization: null,
    permission: null
}

export default function dataState(state = defaultValue, action: any) {
    return produce(state, (draft) => {
        switch (action.type) {
            case `${LOGIN}_FULFILLED`:
                return action.payload
            case LOGOUT:
                axios.defaults.headers.common.Authorization = ''
                draft.token = null
                break
            case `${SYNC_USER}_FULFILLED`:
                return {
                    ...action.payload,
                    token: state.token
                }
            default:
                break
        }
    })
}

