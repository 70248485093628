import React from 'react'
import Lottie from 'react-lottie'
import '../../styles/elements/Loader.sass'

const SimpleLoader: React.FC = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: require('../../media/animations/simple-loader.json'),
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return (
        <div className="simple-loader-container">
            <Lottie options={defaultOptions} height={35} width={35}/>
        </div>
    )
}
export default SimpleLoader
