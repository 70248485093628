import axios from '../customComponents/customAxios'
import { Reducers } from '../types/reducers'
import { syncActiveIntervention, syncAlert, syncForecast, syncIntervention, syncRadarMeteoTimestamps, syncRiverLevel } from './data'
import { getTeam } from './organization'
import { syncPermissions } from './settings_data'
import { syncUser } from './user'

export function syncAppStartData() {
    return async (dispatch: any, getState: () => Reducers) => {
        axios.defaults.headers.common.Authorization = 'Token ' + getState().user.token
        await dispatch(syncForecast())
        await dispatch(syncRiverLevel())
        await dispatch(syncAlert())
        await dispatch(syncIntervention())
        await dispatch(syncActiveIntervention())
        //await dispatch(syncEarthquake())
        await dispatch(syncUser())
        await dispatch(getTeam())
        await dispatch(syncRadarMeteoTimestamps())
        await dispatch(syncPermissions())
    }
}
