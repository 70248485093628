import React from 'react'
import { bindActionCreators, Dispatch } from 'redux'
import { syncAppStartData } from '../../actions/multiple_actions'
import '../../styles/global.sass'
import { connect } from 'react-redux'
import { deepCopy } from '../../functions'
import { SensorData } from '../../types/api'
import { Reducers } from '../../types/reducers'
import ReactFC from 'react-fusioncharts'
import FusionCharts from 'fusioncharts'
import Chart from 'fusioncharts/fusioncharts.charts'
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion'
import customMoment from '../../customComponents/customMoment'

ReactFC.fcRoot(FusionCharts, Chart, FusionTheme)

const dispatchProps = {
    syncAppStartData
}

function mapStateToProps({ data, settings }: Reducers) {
    return {
        data,
        settings
    }
}

interface Props extends DispatchProps, StateProps {}

interface State {
    // State
}

class RiverLevelsPage extends React.Component<Props, State> {
    transformDate(timestamp: number) {
        return customMoment(timestamp).format('HH:mm')
    }

    transformData(data: SensorData[]) {
        const dataLenght = data.length

        return data
            .map(level => {
                return {
                    label: this.transformDate(level.t),
                    value: level.v
                }
            })
            .slice(dataLenght - 26, dataLenght)
    }

    getMinValue(data: SensorData[]) {
        const dataCopy = deepCopy(data)

        return Math.floor(dataCopy.sort((a, b) => a.v - b.v)[0].v) - 1
    }

    render() {
        const rivers = this.props.data.riverLevels

        if (rivers === null) return null

        const chartConfigs = {
            type: 'splinearea', // The chart type
            width: '1500', // Width of the chart
            height: '500', // Height of the chart
            dataFormat: 'json', // Data type
            dataSource: {
                // Chart Configuration
                chart: {
                    caption: 'Fiume reno',
                    subCaption: 'Gandazzolo',
                    xAxisName: 'Ora',
                    yAxisName: 'Metri',
                    numberSuffix: 'm',
                    theme: 'fusion',
                    yAxisMinValue: this.getMinValue(rivers.reno.data),
                    xAxisValuesStep: 10
                },
                // Chart Data
                data: this.transformData(rivers.reno.data),
                trendlines: [
                    {
                        line: [
                            {
                                startvalue: '12.5',
                                color: '#FFEB3B',
                                displayvalue: 'Soglia 1',
                                valueOnRight: '1',
                                thickness: '2'
                            },
                            {
                                startvalue: '14.8',
                                color: '#FF9800',
                                displayvalue: 'Soglia 2',
                                valueOnRight: '1',
                                thickness: '2'
                            },
                            {
                                startvalue: '17',
                                color: '#F44336',
                                displayvalue: 'Soglia 3',
                                valueOnRight: '1',
                                thickness: '2'
                            }
                        ]
                    }
                ]
            }
        }

        return (
            <div className={`App ${this.props.settings.drawerCollapsed ? 'resized-content' : ''}`}>
                <ReactFC {...chartConfigs} />
            </div>
        )
    }
}

type DispatchProps = typeof dispatchProps
type StateProps = ReturnType<typeof mapStateToProps>

function mapDispatchToProps(dispatch: Dispatch) {
    return bindActionCreators(dispatchProps, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RiverLevelsPage)
