import produce from 'immer'
import { LOGIN } from '../actions/user'
import { ReducerGraphic } from '../types/reducers'

const defaultValue: ReducerGraphic = {
    loginError: false
}

export default function dataState(state = defaultValue, action: any) {
    return produce(state, (draft) => {
        switch (action.type) {
            case `${LOGIN}_REJECTED`:
                draft.loginError = true
                break
            default:
                break
        }
    })
}
