import React, { useState } from 'react'
import '../../styles/HomePage.sass'
import { useSelector } from 'react-redux'
import { ReducerData, Reducers, ReducerSettings, ReducerUser } from '../../types/reducers'
import Loader from '../elements/Loader'
import { activeInterventionIcon, organizationIcon } from '../elements/MapIcons'
import RiverLevel from '../elements/RiverLevel'
import AlertTable from '../elements/AlertTable'
import WeatherGrid from '../elements/WeatherGrid'
import { getActualWorkShift } from '../../functions'
import { Map, TileLayer, Marker } from 'react-leaflet'
import { Legend, Viewport } from '../../types/interfaces'

const HomePage: React.FC = () => {
    const [openFiltersList, setOpenFiltersList] = useState<boolean>(false)
    const [viewport, setViewport] = useState<Viewport>({ center: [44.6060402, 11.6589668], zoom: 12 })
    const [interventionsVisible, setInterventionsVisible] = useState<boolean>(false)
    const [riverLevelsVisible, setRiverLevelsVisible] = useState<boolean>(false)
    const [meteoVisible, setMeteoVisible] = useState<boolean>(false)

    const data: ReducerData = useSelector<Reducers, ReducerData>(store => store.data)
    const userReducer: ReducerUser = useSelector<Reducers, ReducerUser>(store => store.user)
    const settings: ReducerSettings = useSelector<Reducers, ReducerSettings>(store => store.settings)

    const rivers = data.riverLevels
    const alerts = data.alerts
    const forecast = data.forecast
    const interventions = data.interventions
    const activeInterventions = data.activeInterventions
    const radarMeteoTimestamps = data.radarMeteoTimestamps
    if (
        rivers === null ||
        alerts === null ||
        forecast === null ||
        interventions === null ||
        activeInterventions === null ||
        radarMeteoTimestamps === null
    )
        return <Loader/>

    const alert = alerts.today.filter(element => element.area === 'D' || element.area === 'D1')

    const lastRadarImage = radarMeteoTimestamps[radarMeteoTimestamps.length - 1]

    const user = userReducer

    const legends: Legend[] = [
        {
            name: 'Pioggerella (probabile)',
            class: 'probably-drizzle'
        },
        {
            name: 'Pioggerella',
            class: 'drizzle'
        },
        {
            name: 'Pioggia leggera',
            class: 'light-rain'
        },
        {
            name: 'Pioggia leggera',
            class: 'light-rain-2'
        },
        {
            name: 'Pioggia',
            class: 'rain-box'
        },
        {
            name: 'Pioggia',
            class: 'rain-box-2'
        },
        {
            name: 'Forte pioggia',
            class: 'heavy-rain'
        },
        {
            name: 'Forte pioggia',
            class: 'heavy-rain-2'
        },
        {
            name: 'Tempesta',
            class: 'storm'
        },
        {
            name: 'Tempesta',
            class: 'storm-2'
        },
        {
            name: 'Grandine',
            class: 'hail-box'
        },
        {
            name: 'Grandine',
            class: 'hail-box-2'
        }
    ]

    return (
        <div className={`App ${settings.drawerCollapsed ? 'resized-content' : ''}`}>
            <div className="meteo-widget">
                <WeatherGrid forecast={forecast}/>
            </div>
            <div className="main-card card">
                <div className="left-wrapper">
                    <div className="alerts-section">
                        <div className="workshift">
                            <div className="workshift__header">
                                <span>TURNO</span>
                                <div className={`${getActualWorkShift().night ? 'night-icon' : 'day-icon'}`}/>
                            </div>
                            <div className="workshift__body">{getActualWorkShift().shift}</div>
                        </div>
                        <AlertTable home={true} data={alert}/>
                    </div>
                </div>
                <div className="map-wrapper">
                    <div className="map-container">
                        <Map
                            className='map-container'
                            viewport={viewport}
                            onViewportChanged={(view) => setViewport(view)}
                        >
                            <TileLayer
                                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <TileLayer
                                url='https://maps.activepager.com/geoserver/gwc/service/tms/1.0.0/hydrologis:hydrants@EPSG%3A900913@pbf/{z}/{x}/{y}.pbf'
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            />
                            {
                                meteoVisible ? (
                                    <TileLayer
                                        url={`https://tilecache.rainviewer.com/v2/radar/${lastRadarImage}/512/{z}/{x}/{y}/1/1_1.png`}
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    />
                                ) : null
                            }
                            <Marker
                                position={[user.organization.position.latitude, user.organization.position.longitude]}
                                icon={organizationIcon}
                            />
                            {
                                interventionsVisible
                                    ? activeInterventions.map(activeIntervention => {
                                        return (
                                            <Marker
                                                position={[activeIntervention.position.latitude, activeIntervention.position.longitude]}
                                                icon={activeInterventionIcon}
                                            />
                                        )
                                    })
                                    : null
                            }
                        </Map>
                    </div>
                    <div className="map-info-panel">
                        <div className="legend">
                            {legends.map((element, index) => (
                                <div className="legend-element" key={index}>
                                    <div className={`color-square ${element.class}`}/>
                                    <span>{element.name}</span>
                                </div>
                            ))}
                        </div>
                        <div className="map-filters-controller">
                            <div className="filters-wrapper">
                                <div className="filter-trigger" onClick={() => setOpenFiltersList(!openFiltersList)}>
                                    <span>Filtri</span>
                                    <div className={`triangle-icon ${openFiltersList ? '' : 'triangle-down'}`}/>
                                </div>
                                <div className={`filters-list ${openFiltersList ? 'visible-list' : ''}`}>
                                    <div className="filter">
                                        <span>Fiumi</span>
                                        <div className="md-checkbox">
                                            <input
                                                id="checkO"
                                                type="checkbox"
                                                onChange={() => setRiverLevelsVisible(!riverLevelsVisible)}
                                                checked={riverLevelsVisible}
                                            />
                                            <label htmlFor="checkO"></label>
                                        </div>
                                    </div>
                                    <div className="filter">
                                        <span>Interventi</span>
                                        <div className="md-checkbox">
                                            <input
                                                id="checkT"
                                                type="checkbox"
                                                onChange={() => setInterventionsVisible(!interventionsVisible)}
                                                checked={interventionsVisible}
                                            />
                                            <label htmlFor="checkT"></label>
                                        </div>
                                    </div>
                                    <div className="filter">
                                        <span>Meteo</span>
                                        <div className="md-checkbox">
                                            <input
                                                id="checkTh"
                                                type="checkbox"
                                                onChange={() => setMeteoVisible(!meteoVisible)}
                                                checked={meteoVisible}
                                            />
                                            <label htmlFor="checkTh"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="rivers-alerts">
                            <RiverLevel river={rivers.reno}/>
                            <RiverLevel river={rivers.idiceMartino}/>
                            <RiverLevel river={rivers.idiceAntonio}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HomePage
