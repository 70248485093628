import customAxios from '../customComponents/customAxios'
import axios from 'axios'
import moment from 'moment'

moment().locale('it')

/**
 * METEO
 */

// Scarica i dati del meteo
export function syncForecast() {
    return {
        type: 'data/syncForecast',
        payload: customAxios.get('dashboard/forecast/').then(response => {
            return response.data
        })
    }
}

/**
 * LIVELLI IDROMETRICI
 */

// Scarica i dati dei livelli dei fiumi
export function syncRiverLevel() {
    return {
        type: 'data/syncRiverLevel',
        payload: customAxios.get('dashboard/river_level/').then(response => response.data)
    }
}

/**
 * ALLERTE
 */

// Scarica i dati delle allerte
export function syncAlert() {
    return {
        type: 'data/syncAlert',
        payload: customAxios.get('dashboard/alert/').then(response => response.data)
    }
}

/**
 * TERREMOTI
 */

// Scarica i dati dei terremoti
export function syncEarthquake() {
    const startDate = moment()
        .subtract(7, 'd')
        .format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')

    return {
        type: 'data/syncEarthquake',
        payload: customAxios
            .get(`dashboard/earthquake/?start=${startDate}&end=${endDate}`, {
                headers: { 'Content-Type': 'text/xml' }
            })
            .then(response => {
                return {
                    emilia: JSON.parse(response.data.emilia)['q:quakeml'].eventParameters.event,
                    italy: JSON.parse(response.data.italy)['q:quakeml'].eventParameters.event
                }
            })
    }
}

/**
 * INTERVENTI
 */

// Scarica i dati degli interventi
export function syncIntervention() {
    return {
        type: 'data/syncIntervention',
        payload: customAxios.get('dashboard/intervention/').then(response => {
            return response.data
        })
    }
}

/**
 * INTERVENTI ATTIVI
 */


// Scarica i dati degli interventi
export function syncActiveIntervention() {
    return {
        type: 'data/syncActiveIntervention',
        payload: customAxios.get('dashboard/intervention/active_interventions/').then(response => {
            return response.data
        })
    }
}

/**
 * RADAR TIMESTAMPS
 */

// Scarica i dati degli interventi
export function syncRadarMeteoTimestamps() {
    return {
        type: 'data/syncRadarMeteoTimestamps',
        payload: axios.get('https://api.rainviewer.com/public/maps.json').then(response => {
            return response.data
        })
    }
}

