import axios from '../customComponents/customAxios'
import { LoginData, User } from '../types/interfaces'

export const LOGIN = 'user/login'
export const LOGOUT = 'user/logout'
export const SYNC_USER = 'user/syncUser'
export const CHANGE_NOTIFICATION = 'user/changeNotification'

// Fa il login
export function login(userData: LoginData) {
    return {
        type: LOGIN,
        payload: async () => {
            const token = await axios.post('access/login/', userData).then(result => result.data.key)
            axios.defaults.headers.common.Authorization = 'Token ' + token
            const user: User = await axios.get('dashboard/user/').then(response => response.data[0])
            return { token, ...user }
        }
    }
}

// Fa il logout
export function logout() {
    return {
        type: LOGOUT
    }
}

// Sincronizza l'utente
export const syncUser = () => ({
    type: SYNC_USER,
    payload: axios.get('dashboard/user/').then(response => response.data[0])
})

export function changeNotificationValue(userId: number, key: string, value: boolean) {
    return {
        type: CHANGE_NOTIFICATION,
        payload: axios
            .patch(`dashboard/user/${userId}/`, {
                key: value
            })
            .then(response => response.data)
    }
}
