import axios from '../customComponents/customAxios'

export const SYNC_PERMISSIONS = 'user/syncPermissions'

// Scarica i vari permessi
export function syncPermissions() {
    return {
        type: SYNC_PERMISSIONS,
        payload: axios.get('dashboard/user_permissions/').then(response => response.data)
    }
}
